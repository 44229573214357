/* Media Mobile */

@media screen and (max-width: 330px) {
  .fontAuto {
    font-size: 20px;
  }
  .fontH1 {
    font-size: 28px;
  }
}
@media screen and (max-width: 600px) {
  .fontAuto {
    font-size: 20px;
  }
  .fontH1 {
    font-size: 32px;
  }
  .fondoNav{
    background-color: #060606 !important;
  }
}
@media screen and (max-width: 400px) {
  .rn-header.sticky {
    height: 50px !important;
  }
  .header-default {
    height: 50px !important;
  }
}
@media screen and (min-width: 401px) {
  .rn-header.sticky {
    height: 100px !important;
  }
  .header-default {
    height: 100px !important;
  }
}

